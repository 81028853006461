import React from 'react'
import './Steps.css';
import img from '../images/detective-thumb-1.png';
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'

export default function Steps() {
    const d = new Date();
    let year = d.getFullYear().toString();

    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    return (
        <div style={{ backgroundColor: "#E6ECF0" }} className='mt-4'>
            <LoadingBar color='#07a3bc' progress={100} height={5} />
            <Helmet>
                <title>Steps to become Detective - How to become Detective - {year}</title>
                <meta name='description' content='Introduction Steps to becoming a detective INTRODUCTION To provide protection against potential threats and crime, detectives are in high demand by people, families, companies and businesses. Detectives may work independently as private investigators, or they may work in collaboration with other detectives, police and agencies to protect society from suspected threats and crime. You must... Read More' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="container-fluid" style={{backgroundColor: '#07a3bc', color: 'white'}}>
                <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                    STEPS TO BECOME A DETECTIVE
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#07a3bc" data-header-text-color="white" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '765px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=07a3bc&amp;header_text_color=white&amp;href=https%3A%2F%2Fwww.howtobecomedetective.com%2F" id="iFrameResizer0"></iframe></div></div>
                        <br />
                        <ul className="left-sec pl-0 sticky-top" style={{ listStyleType: 'none', width: '100%' }}>
                            <li><a href="#intro" onClick={(e) => removeDefault(e)}>Introduction</a></li>
                            <li><a href="#steps-to-become-detective" onClick={(e) => removeDefault(e)}>Steps to Becoming a detective</a></li>
                            <li><a href="#sugges" onClick={(e) => removeDefault(e)}>Suggestions</a></li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="row ml-0 mr-0">
                            <h2 id="intro" className="sec-heading">INTRODUCTION</h2>
                        </div>
                        <p>
                            To provide protection against potential threats and crime, detectives are in high demand by people, families, companies and businesses. Detectives may work independently as private investigators, or they may work in collaboration with other detectives, police and agencies to protect society from suspected threats and crime. You must understand that becoming a detective is a high risk career involving highly professional investigative standards, commitments, strenuous hours of training, and strict licensing exams. If you are really interested to become a detective, then carefully consider the following steps. These steps would guide you take a right decision and become a detective. They contain in-detail information on relevant education, training, entrance and license requirements for detectives to conduct investigation per state regulations across the U.S.
                        </p>
                        <div className="container">
                            <div className="row" style={{ border: '1px solid #41b3a3', borderRadius: '10px', backgroundColor: '#FFC107' }}>
                                <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                    <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>STEPS TO BECOME A DETECTIVE</h5>
                                </div>
                                <div className="col-md-5 pt-2 pb-2 pr-2 pl-2">
                                    <img src={img} style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0">
                            <h2 id="steps-to-become-detective" className="sec-heading">Steps To Becoming A Detective</h2>
                        </div>
                        <div className="container-fluid p-0" id="steps">
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 1: Educational Requirements</h3>
                                    <p className="text-justify">Depending upon your state regulations, you need a minimum of a high school diploma or equivalent to formal education of associate or bachelor’s degree in private investigation, criminal justice, criminal procedure, forensic sciences, or a related law enforcement field. You should have basic computer skills to help you run the investigative software, database, and crime scene analysis. You’ll also be required to comfortably use investigative technology and surveillance equipment. Most states require detective licensing candidates to meet professional experience requirements. There is a varying length of experience required by the employer. For instance, five years as a full-time police officer or a fire investigator, three years as a detective with a police agency with a county, state or federal office. Knowing a foreign language would bring additional benefits. Since educational requirements vary from state to state, there is wide selection of detective degrees, training programs and experience opportunities offered by vocational schools, military, community colleges and law enforcement schools. These may take from a few weeks of training to formal 4 year university degrees and post-graduation.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 2: Finding the Job</h3>
                                    <p className="text-justify">Detectives usually begin their career as police officers. They have good employment potential in several specialized areas of investigative work. In multiple capacities they may work independently, as a private detective, or work in collaboration with police or law enforcing agencies. Very often these jobs are full-time with paid overtime prospects. To begin working as a detective, around 90 days are required to complete the entire application process for the grant of a detective license. According to the Bureau of Labor Statistics (BLS 2017), the median annual wage for police and private detectives was $62,960, while in comparison, the police investigators and criminal investigators reported significantly higher median salary range of $79,970 per year. There is an anticipation of about 11% growth between the years 2016 to 2026. The potential employers expect detectives to meet highest standards of professional excellence.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 3: Training Requirements</h3>
                                    <p className="text-justify">As the job meets the public’s need for protection and justice, the detectives are exposed to the dangers of facing the criminals, suffering injuries or even death, so the detective license is granted after a rigorous process of selection criteria, written work, viewing physical fitness profile and interviewing. Private detectives may either apply for an armed or unarmed investigation license. They may be required to complete a formal firearms training course, or rights and responsibilities training course that empowers them to help law enforcing agencies arrest the criminals. A separate Concealing Weapon Permit is required to carry a concealed weapon. Having a military training experience is an added benefit. Detective training regulations may vary state to state so see your state requirements for more information.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 4: Licenses and Certifications</h3>
                                    <p className="text-justify">Private detectives are required to file state licensing application. They must also submit fingerprints, background check, provide two passport photos and pay application fee. The state agency then conducts a private investigator proficiency test that requires high standards of ethics, professionalism, no legal convictions, and clean bank debt records. Depending upon state regulations, the private candidates are required to carry insurance policies to carry out their investigation career. Detectives can have a variety of certifications suiting areas of detective work and job nature. Experienced law enforcing detectives may qualify to take the National Detective/ Investigation Test (NDIT). Investigators specializing in criminal defense investigation are required to take the Certified Legal Investigator (CLI) certification. Security investigators may take Professional Certified Investigator (PCI) certification.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0">
                            <h2 id="sugges" className="sec-heading">Suggestions</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                    <li><a href="https://www.stepstobecomea.com/border-patrol-officer/">How to Become a Border Patrol in USA</a></li>
                                    <li><a href="https://www.stepstobecomea.com/criminologist/">How to Become a Criminologist in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/court-reporter/">How to Become a Court Reporter in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/cia-agent/">How to Become a CIA Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/crime-scene-investigator/">How to Become a Crime Scene Investigator in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/cosmetologist/">How to Become a Cosmetologist in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/data-analyst/">How to Become a Data Analyst in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/fbi-agent/">How to Become a FBI Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/financial-advisor/">How to Become a Financial Advisor in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/financial-analyst/">How to Become a Financial Analyst in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/parole-officer/">How to Become a Parole Officer in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/police-officer/">How to Become a Police Officer in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-california/">How to Become a Police Officer in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-florida/">How to Become a Police Officer in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-pennsylvania/">How to Become a Police Officer in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-new-york/">How to Become a Police Officer in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-texas/">How to Become a Police Officer in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/private-investigator/">How to Become a Private Investigator in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/real-estate-agent/">How to Become a Real Estate Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/realtor/">How to Become a Realtor in USA</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                    <li><a href="https://stepstobecomea.com/sheriff/">How to Become a Sheriff in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-california/">How to Become a Sheriff in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-florida/">How to Become a Sheriff in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-new-york/">How to Become a Sheriff in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-pennsylvania/">How to Become a Sheriff in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-texas/">How to Become a Sheriff in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter/">How to Become a Firefighter in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-california/">How to Become a Firefighter in California</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-florida/">How to Become a Firefighter in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-new-york/">How to Become a Firefighter in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-pennsylvania/">How to Become a Firefighter in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-texas/">How to Become a Firefighter in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/cpa/">How to Become a CPA in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/forensic-scientist/">How to Become a Border Patrol in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-california/">How to Become a Forensic Scientist in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-florida/">How to Become a Forensic Scientist in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-new-york/">How to Become a Forensic Scientist in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-pennsylvania/">How to Become a Forensic Scientist in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-texas/">How to Become a Forensic Scientist in Texas</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}
