import React from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../images/d-logo.png';

export default function Header() {
    function openNav() {
        document.getElementById("links2").style.width = "60%";
    }

    function closeNav() {
        document.getElementById("navbarSupportedContent").classList.remove("show");
    }

    return (
        <header id='headerDetective'>
            <div className="container-fluid">
                <div className="row">
                    <button className="navbar-toggler d-lg-none d-block" type="button" data-toggle="collapse" onClick={openNav} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center pt-md-3 pb-md-4">
                    <NavLink rel="home" to={`/`}>
                        <img src={logo} />
                    </NavLink>
                </div>
            </div>

            <div className="container p-0">
                <nav className="navbar navbar-expand-lg navbar-dark p-0" style={{ backgroundColor: '#07a3bc' }}>
                    <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mr-auto" id="links2">
                            <li className="nav-item active border-0 d-lg-none d-block text-center bg-white">
                                <img src={logo} style={{width: "50%"}} />
                                <button className="close" data-dismiss="navbar-collapse" onClick={closeNav} aria-expanded="false" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={closeNav} to={`/`}>HOME</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={closeNav} to={`/steps-to-become-a-detective/`}>STEPS</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={closeNav} to={`/requirements-to-become-a-detective/`}>REQUIREMENTS</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={closeNav} to={`/states/`}>STATES</NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}