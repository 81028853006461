import React from 'react'
import './Index.css';
import img from '../images/detective-thumb-1.png';
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'

export default function Index() {
    const d = new Date();
    let year = d.getFullYear().toString();

    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    window.history.pushState({}, null, '/');

    return (
        <div style={{ backgroundColor: "#E6ECF0" }} className='mt-4'>
            <LoadingBar color='#07a3bc' progress={100} height={5} />
            <Helmet>
                <title>How to Become a Detective - Becoming a Detective - {year}</title>
                <meta name='description' content='Introduction What Do Detectives Do? Types of Detectives Detective Salaries and Job Growth Steps to becoming a detective Detective Certifications Detective Career Advancement Should You Become a Detective? Going Away Tip INTRODUCTION When a crime is committed it needs investigation leading to arrest of the criminal. So, a detective (also an investigator) takes up the... Read More' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="container-fluid" style={{backgroundColor: '#07a3bc', color: 'white'}}>
                <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                    HOW TO BECOME A DETECTIVE
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#07a3bc" data-header-text-color="white" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '765px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=07a3bc&amp;header_text_color=white&amp;href=https%3A%2F%2Fwww.howtobecomedetective.com%2F" id="iFrameResizer0"></iframe></div></div>
                        <br />
                        <ul className="left-sec pl-0 sticky-top" style={{ listStyleType: 'none', width: '100%' }}>
                            <li><a href="#intro" onClick={(e) => removeDefault(e)}>Introduction</a></li>
                            <li><a href="#what-do-detective" onClick={(e) => removeDefault(e)}>What Do Detectives Do?</a></li>
                            <li><a href="#types-of-detective" onClick={(e) => removeDefault(e)}>Types of Detectives</a></li>
                            <li><a href="#detective-salaries" onClick={(e) => removeDefault(e)}>Detective Salaries and Job Growth</a></li>
                            <li><a href="#steps-to-become-detective" onClick={(e) => removeDefault(e)}>Steps to Becoming a detective</a></li>
                            <li><a href="#detective-certifications" onClick={(e) => removeDefault(e)}>Detective Certifications</a></li>
                            <li><a href="#detective-career" onClick={(e) => removeDefault(e)}>Detective Career Advancement</a></li>
                            <li><a href="#should-you-become" onClick={(e) => removeDefault(e)}>Should You Become a Detective?</a></li>
                            <li><a href="#going-away-tip" onClick={(e) => removeDefault(e)}>Going Away Tip</a></li>
                            <li><a href="#sugges" onClick={(e) => removeDefault(e)}>Suggestions</a></li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="row ml-0 mr-0">
                            <h2 id="intro" className="sec-heading">INTRODUCTION</h2>
                        </div>
                        <p>
                            When a crime is committed it needs investigation leading to arrest of the criminal. So, a detective (also an investigator) takes up the investigation task and works with the law enforcement agencies by collecting the crime scene evidence.
                        </p>
                        <p>
                            Detectives work either full time, part time, independently or in collaboration with other detectives. They could be paid for their overtime hours. There are often long working shifts and they may not get a regular promotion like police officers. Detectives are driven by their intrinsic desire to bring peace and justice to the society by protecting the society against the criminals. Privately hired detectives really enjoy delivering their expertise on the types of cases they take up for investigation. But for the purpose, they must meet the state specific requirements, training and licensing regulations.
                        </p>
                        <p>
                            Detectives are hired to help the law in restoring public’s need for protection and justice. This is a high risk profession where the detectives would often be facing the criminals, personal injury, and even death. The real detective work is quite far away as portrayed glamorously in the movies.
                        </p>
                        <div className="container">
                            <div className="row" style={{ border: '1px solid #41b3a3', borderRadius: '10px', backgroundColor: '#FFC107' }}>
                                <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                    <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>STEPS TO BECOME A DETECTIVE</h5>
                                </div>
                                <div className="col-md-5 pt-2 pb-2 pr-2 pl-2">
                                    <img src={img} style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0">
                            <h2 id="what-do-detective" className="sec-heading">What Do Detectives Do?</h2>
                        </div>
                        <p>Here is a list of what detectives do:</p>
                        <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Conduct investigations in wide range, multiple areas</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Recover stolen property</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Secure crime scene evidence</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Interview witnesses and suspects</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Prepare long reports and testify in the court of law</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i>  Place individuals or suspects under surveillance</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Work with government or law enforcing agencies at local, state or national levels to investigate crimes</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Investigate crime, violence and potential threats of violence</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Take part in conducting arrests</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Provide  records involving legal investigations</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Track missing people</li>
                        </ul>
                        <div className="row ml-0 mr-0">
                            <h2 id="types-of-detective" className="sec-heading">Types of Detectives</h2>
                        </div>
                        <p>Depending upon the needs, one can hire a skilled private detective to protect one’s self, family, company or business from potential threats and crime. Different detective categories are available for different responsibilities.</p>
                        <p>Here is a list of different categories of detectives:</p>
                        <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Legal Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Insurance Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Financial Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Corporate Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Computer Forensics Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Police Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Private Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Homicide Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Cybercrime Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Gang and Narcotics Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Juvenile Detectives</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Technical Investigators</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Forensic Detectives</li>
                        </ul>
                        <div className="row ml-0 mr-0">
                            <h2 id="detective-salaries" className="sec-heading">Detective Salaries And Job Growth</h2>
                        </div>
                        <p>According to the Bureau of Labor Statistics (BLS 2017) the demand for private detectives and investigators will grow by 11% between 2016 and 2026. This is much higher than police detectives whose job demand will comparatively grow by 7% percent. Many private investigators turn up from a law enforcement making it rather tough for the new entrants. In May 2017 the median annual wage for police and private detectives was $62,960, while in comparison, the police investigators and criminal investigators reported significantly higher median salary range of $79,970 per year.</p>
                        <p>The competition in this field is getting more and more intense as the personnel from the law enforcement training background get more job opportunities, so the aspirants intending to become police detectives should ensure to meet, rather excel, beyond all the job requirements and expectations.</p>
                        <p>The following statistics are taken from the US Bureau of Labor Statistics (BLS) and reflect detectives’ median annual wages as of 2017.</p>
                        <table className="table text-center table-bordered">
                            <tbody>
                                <tr style={{backgroundColor: 'rgb(38 156 175)', color: 'white'}}>
                                    <th style={{fontWeight: 'normal'}}>Legal Occupations</th>
                                    <th style={{fontWeight: 'normal'}}>Detectives</th>
                                    <th style={{fontWeight: 'normal'}}>Total, all Occupations</th>
                                </tr>
                                <tr style={{fontSize: '13px'}}>
                                    <td>$80,080</td>
                                    <td>$119,250</td>
                                    <td>$37,690</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>If you are interested in knowing who employs detectives the most, below is a list of Top 4 employers:</p>
                        <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Federal government</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Law enforcement agencies</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Private hiring</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Security managing agencies</li>
                        </ul>
                        <p>It appears that detective jobs are expected to grow from 2016 to 2026. The following table will give you a clearer picture of future job growth in this field. This set of statistics is also taken from BLS 2017.</p>
                        <table className="table text-center table-bordered">
                            <tbody>
                                <tr style={{backgroundColor: 'rgb(38 156 175)', color: 'white'}}>
                                    <th style={{fontWeight: 'normal'}}>Total, all Occupations</th>
                                    <th style={{fontWeight: 'normal'}}>Investigative Occupations</th>
                                    <th style={{fontWeight: 'normal'}}>Detectives</th>
                                </tr>
                                <tr style={{fontSize: '13px'}}>
                                    <td>7%</td>
                                    <td>11%</td>
                                    <td>8%</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>In layman terms, detectives’ jobs will increase at the rate of 11% from 2016 to 2026. In case you are interested in knowing the actual number of jobs created in this field from 2016 to 2026, then here are another set of statistics taken from the BLS.</p>
                        <table className="table text-center table-bordered table-responsive-md">
                            <tbody>
                                <tr style={{backgroundColor: 'rgb(38 156 175)', color: 'white'}}>
                                    <th style={{fontWeight: 'normal'}}>Occupational Title</th>
                                    <th style={{fontWeight: 'normal'}}>Employment, 2016</th>
                                    <th style={{fontWeight: 'normal'}}>Projected Employment, 2026</th>
                                    <th style={{fontWeight: 'normal'}}>Percent</th>
                                    <th style={{fontWeight: 'normal'}}>Numeric</th>
                                </tr>
                                <tr style={{fontSize: '13px'}}>
                                    <td>Detectives</td>
                                    <td>41,400</td>
                                    <td>857,500</td>
                                    <td>8</td>
                                    <td>65,000</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="row ml-0 mr-0">
                            <h2 id="steps-to-become-detective" className="sec-heading">Steps To Becoming A Detective</h2>
                        </div>
                        <div className="container-fluid p-0" id="steps">
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 1: Academic Requirement</h3>
                                    <p className="text-justify">Depending upon your state regulations, you need a minimum of a high school diploma or equivalent to formal education of associate or bachelor’s degree in private investigation, criminal justice or related field.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 2: Gaining Professional Experience</h3>
                                    <p className="text-justify">Most states require detective licensing candidates to meet professional experience requirements. There is a varying length of experience required. For instance, five years as a full-time police officer or a fire investigator, three years as a detective with a police agency with a county, state or federal office..</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 3: Acquiring Firearms Training and Licensing (optional)</h3>
                                    <p className="text-justify">Private detectives may either apply for an armed or unarmed investigation license. They may be required to complete a formal firearms training course, or rights and responsibilities training course that empowers them to help law enforcing agencies arrest the criminals. A separate Concealing Weapon Permit is required to carry a concealed weapon.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 4: State Licensing Examination</h3>
                                    <p className="text-justify">Private detectives are required to file state licensing application. They must also submit fingerprints, background check, provide two passport photos and pay application fee. The state agency then conducts a private investigator proficiency test.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 5: File Insurance Policy</h3>
                                    <p className="text-justify">Depending upon state regulations, the private candidates are required to carry insurance policies to carry out investigation.</p>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-2">
                                    <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                </div>
                                <div className="col-9 ml-2">
                                    <h3 className="gdlr-process-title">Step 6: Apply for Employment</h3>
                                    <p className="text-justify">To begin working as a detective, around 90 days are required to complete the entire application process for the grant of a detective license.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0">
                            <h2 id="detective-certifications" className="sec-heading">Detective Certifications</h2>
                        </div>
                        <p>Detectives can have a variety of certifications suiting areas of detective work and job nature. Experienced law enforcing detectives may qualify to take the National Detective/ Investigation Test (NDIT). Investigators specializing in criminal defense investigation are required to take the Certified Legal Investigator (CLI) certification. Security investigators may take Professional Certified Investigator (PCI) certification. Since educational requirements vary from state to state, there is wide selection of detective degrees, training programs and experience opportunities offered by vocational schools, military, community colleges and law enforcement schools. These may take from a few weeks of training to formal 4 year university degrees and post-graduation.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="detective-career" className="sec-heading">Detective Career Advancement</h2>
                        </div>
                        <p>Detectives’ earnings depend on their law enforcing training background, experience, qualification and area of specialization. Being a detective is a specialized career where experience is equally important as formal training and there are many opportunities for detectives to take their careers forward thorough detective specific skills and specialized training.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="should-you-become" className="sec-heading">Should You Become a Detective?</h2>
                        </div>
                        <p>Do you wish to be a detective and want to know if you are suited for the job? Have a look at the qualities and skills required to be a good detective and you will have your answer.</p>
                        <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Sharp perception</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Analytical skills</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Critical thinking</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Patience</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Leadership skills</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Decision-making skills</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Speaking skills</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Communication skills</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Research/ writing skills</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Physical stamina</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Basic computer skills using crime related software/ databases</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Multitasking</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i>Comfortable using firearms, polygraphs, surveillance equipment</li>
                        </ul>
                        <div className="row ml-0 mr-0">
                            <h2 id="going-away-tip" className="sec-heading">Going Away Tip</h2>
                        </div>
                        <p>Having a sharp analytical mind, good communication, and physical fitness is great but still you need to take this career more seriously. Obtaining a secondary education in law enforcement related field, such as an associate degree in criminal justice, would turn your career really fruitful as a detective.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="sugges" className="sec-heading">Suggestions</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                    <li><a href="https://www.stepstobecomea.com/border-patrol-officer/">How to Become a Border Patrol in USA</a></li>
                                    <li><a href="https://www.stepstobecomea.com/criminologist/">How to Become a Criminologist in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/court-reporter/">How to Become a Court Reporter in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/cia-agent/">How to Become a CIA Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/crime-scene-investigator/">How to Become a Crime Scene Investigator in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/cosmetologist/">How to Become a Cosmetologist in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/data-analyst/">How to Become a Data Analyst in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/fbi-agent/">How to Become a FBI Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/financial-advisor/">How to Become a Financial Advisor in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/financial-analyst/">How to Become a Financial Analyst in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/parole-officer/">How to Become a Parole Officer in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/police-officer/">How to Become a Police Officer in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-california/">How to Become a Police Officer in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-florida/">How to Become a Police Officer in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-pennsylvania/">How to Become a Police Officer in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-new-york/">How to Become a Police Officer in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-texas/">How to Become a Police Officer in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/private-investigator/">How to Become a Private Investigator in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/real-estate-agent/">How to Become a Real Estate Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/realtor/">How to Become a Realtor in USA</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                    <li><a href="https://stepstobecomea.com/sheriff/">How to Become a Sheriff in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-california/">How to Become a Sheriff in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-florida/">How to Become a Sheriff in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-new-york/">How to Become a Sheriff in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-pennsylvania/">How to Become a Sheriff in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-texas/">How to Become a Sheriff in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter/">How to Become a Firefighter in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-california/">How to Become a Firefighter in California</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-florida/">How to Become a Firefighter in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-new-york/">How to Become a Firefighter in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-pennsylvania/">How to Become a Firefighter in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-texas/">How to Become a Firefighter in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/cpa/">How to Become a CPA in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/forensic-scientist/">How to Become a Border Patrol in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-california/">How to Become a Forensic Scientist in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-florida/">How to Become a Forensic Scientist in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-new-york/">How to Become a Forensic Scientist in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-pennsylvania/">How to Become a Forensic Scientist in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-texas/">How to Become a Forensic Scientist in Texas</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}
