import React from 'react'
import './Requirements.css';
import img from '../images/detective-thumb-1.png';
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar'

export default function Requirements() {
    const d = new Date();
    let year = d.getFullYear().toString();

    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    return (
        <div style={{ backgroundColor: "#E6ECF0" }} className='mt-4'>
            <LoadingBar color='#07a3bc' progress={100} height={5} />
            <Helmet>
                <title>Requirements to become Detective - How to become Detective - {year}</title>
                <meta name='description' content='Introduction Minimum requirements to become a detective Education and Qualifications REQUIREMENTS OF THE APPLICATION PROCESS PASSING A DETECTIVE LICENSING STATE EXAM REQUIREMENTS PSYCHOLOGICAL PROFILE EVALUATION ESTABLISHING YOURSELF IN THE FIELD AS A DETECTIVE PERSONAL COMMITMENT AS A DETECTIVE APPLICATION PROCESSING TIME TRAINING INTRODUCTION In the existing complex society of innovative technology, networking and dense nature... Read More' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="container-fluid" style={{ backgroundColor: '#07a3bc', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                    REQUIREMENTS TO BECOME A DETECTIVE
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#07a3bc" data-header-text-color="white" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '765px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=07a3bc&amp;header_text_color=white&amp;href=https%3A%2F%2Fwww.howtobecomedetective.com%2F" id="iFrameResizer0"></iframe></div></div>
                        <br />
                        <ul className="left-sec pl-0 sticky-top" style={{ listStyleType: 'none', width: '100%' }}>
                            <li><a href="#intro" onClick={(e) => removeDefault(e)}>Introduction</a></li>
                            <li><a href="#min-req" onClick={(e) => removeDefault(e)}>Minimum requirements to become a detective</a></li>
                            <li><a href="#edu-and-qualification" onClick={(e) => removeDefault(e)}>Education and Qualifications</a></li>
                            <li><a href="#req-of-app-process" onClick={(e) => removeDefault(e)}>REQUIREMENTS OF THE APPLICATION PROCESS</a></li>
                            <li><a href="#license-exam" onClick={(e) => removeDefault(e)}>PASSING A DETECTIVE LICENSING STATE EXAM REQUIREMENTS</a></li>
                            <li><a href="#profile" onClick={(e) => removeDefault(e)}>PSYCHOLOGICAL PROFILE EVALUATION</a></li>
                            <li><a href="#establish" onClick={(e) => removeDefault(e)}>ESTABLISHING YOURSELF IN THE FIELD AS A DETECTIVE</a></li>
                            <li><a href="#personal-commitment" onClick={(e) => removeDefault(e)}>PERSONAL COMMITMENT AS A DETECTIVE</a></li>
                            <li><a href="#app-process" onClick={(e) => removeDefault(e)}>APPLICATION PROCESSING TIME</a></li>
                            <li><a href="#training" onClick={(e) => removeDefault(e)}>TRAINING</a></li>
                            <li><a href="#sugges" onClick={(e) => removeDefault(e)}>Suggestions</a></li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="row ml-0 mr-0">
                            <h2 id="intro" className="sec-heading">INTRODUCTION</h2>
                        </div>
                        <p>
                            In the existing complex society of innovative technology, networking and dense nature of crime, the necessity of having detectives around has risen noticeably. Tracking a criminal from crime to court conviction is no doubt a pretty tough job, but the profession of being a detective is among the highly paid, rewarding careers. According to the U.S. Department of Labor’s Bureau of Labor Statistics, the demand for detectives would increase by eleven percent by the year 2026. So, if you wish to become a detective you must carefully take the steps required for your specialized education, examinations, licensing processes, specific training and education. The most important question you need to ask yourself is why you want to become a detective. This is a high risk job that demands a very strong commitment on your part. You would be required to take up several years of rigorous education and training to attain your goals. If you have strong motivation to become a detective, then continue reading the requirements to become a detective in the 50 states of the U.S., Canada, and the District of Columbia.
                        </p>
                        <div className="container">
                            <div className="row" style={{ border: '1px solid #41b3a3', borderRadius: '10px', backgroundColor: '#FFC107' }}>
                                <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                    <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>STEPS TO BECOME A DETECTIVE</h5>
                                </div>
                                <div className="col-md-5 pt-2 pb-2 pr-2 pl-2">
                                    <img src={img} style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0">
                            <h2 id="min-req" className="sec-heading">Minimum Requirements To Become A Detective</h2>
                        </div>
                        <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must have an undergraduate degree from a nationally/ regionally accredited institute</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must pass the state required detective licensing examinations and professional requirements</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must apply to carry an insurance policy as the nature of job involves high risks</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must have a varying length of experience in police, with investigation agencies or as an investigator</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must apply either for an armed or an unarmed investigation license</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must have a strong fitness profile and stamina to meet the long working hours</li>
                            <li><i className="fa fa-check" aria-hidden="true"></i> Must have a separate Concealing Weapon Permit to carry a concealed weapon</li>
                        </ul>
                        <p>The above mentioned are the basic requirements to becoming a detective. Although they are necessary but they alone do not ensure your becoming a detective. These requirements are to give you a start in this career.</p>
                        <p>You must finish your undergraduate degree with a good GPA and then continue building up your detective career by taking up necessary experience, education and trainings in your desired domain of detective work. You need to train your inquisitive mind on the science of logic, inference, fact finding and arguments.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="edu-and-qualification" className="sec-heading">Education and Qualifications</h2>
                        </div>
                        <p>Depending upon your state regulations, you must have at least a high school diploma or equivalent to formal education of associate or a bachelor’s degree from an accredited institution. You may take up majors in private investigation, criminal justice or in a relevant field. This may take from a year to four or five years of study. Additionally, it would be of a great benefit if you have a three years training or service record in police, whether part-time or in full-time. This would be followed by your application for acquiring firearms training and licensing (optional) and state licensing examination. You also be required to carry an insurance cover as this is a high risk profession. According to the U.S. Bureau of Labor Statistics (BLS) of 2017, there would be an 11% increase in detective jobs from 2016 to 2026, with a median salary of $50,700 per year. You may expect a tough job competition across the U.S.</p>
                        <p><strong>Tips:</strong></p>
                        <p>Detectives are hired to help protect people, family, company or business from potential threats and crime. So you need to find out your interest area of detective work that you would like to professionally grow up with.</p>
                        <p>Pass your state required test and licensing requirements before taking up any investigating work. This would be followed by passing the rigorous written and ethical exams.</p>
                        <p>These are the general guidelines, so follow your state required selection and training procedures.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="req-of-app-process" className="sec-heading">REQUIREMENTS OF THE APPLICATION PROCESS</h2>
                        </div>
                        <p>You are required to answer all questions thoroughly and accurately with professional, ethical responsibility.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="license-exam" className="sec-heading">PASSING A DETECTIVE LICENSING STATE EXAM REQUIREMENTS</h2>
                        </div>
                        <p>Private detectives are required to file state licensing application. They must also submit fingerprints, background check, provide two passport photos and pay application fee. The state agency then conducts a private investigator proficiency test.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="profile" className="sec-heading">PSYCHOLOGICAL PROFILE EVALUATION</h2>
                        </div>
                        <p>This step comprises the moral character screening process that might take up a few months. You must have a clean criminal record. You could be taking up several written tests and an interview to get yourself assessed on a psychological suitability as a detective. Each state would require you to have a sound moral and ethical character, would take up your references, that you are law abiding with no law breaking counts on you, and free of debts.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="establish" className="sec-heading">ESTABLISHING YOURSELF IN THE FIELD AS A DETECTIVE</h2>
                        </div>
                        <p>At your career start as a detective at initial positions, it’ll be a bit harder for you to sustain in the field, but gradually you’ll get brighter opportunities if pay close attention to crimes scenes, learn how to gather even the minutest details in your reports.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="personal-commitment" className="sec-heading">PERSONAL COMMITMENT AS A DETECTIVE</h2>
                        </div>
                        <p>You are required to follow the best ethical and legal practices of your profession. You should have a thorough knowledge of your field and in your area of expertise. You should be committed to your professional growth. You should set yourself in working with other detectives, police and law agencies, collecting evidence, preparing legal reports, and thoroughly knowing court procedures.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="app-process" className="sec-heading">APPLICATION PROCESSING TIME</h2>
                        </div>
                        <p>As the application process is a rigorous one, so you might expect getting it completed from around 90 days or a bit more.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="training" className="sec-heading">TRAINING</h2>
                        </div>
                        <p>An undergraduate degree from an accredited institute and a sound training in the area of your choice detective work, with a state license of would make you eligible for being a detective. The detectives are required to pass the state licensing requirements in accordance with each state’s own regulations. You are also expected to have a strong physical training, patience and stamina to handle job danger and stress. Typically, the detectives are chosen from existing police officers. So, it might take you to serve at least three years as a police officer before becoming eligible for detective positions. You may stand better chances of being a detective if you have a military training and experience. As a private detective you’ll be required to complete a formal firearms training course, and apply for an armed or unarmed investigation license.</p>
                        <div className="row ml-0 mr-0">
                            <h2 id="sugges" className="sec-heading">Suggestions</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                    <li><a href="https://www.stepstobecomea.com/border-patrol-officer/">How to Become a Border Patrol in USA</a></li>
                                    <li><a href="https://www.stepstobecomea.com/criminologist/">How to Become a Criminologist in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/court-reporter/">How to Become a Court Reporter in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/cia-agent/">How to Become a CIA Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/crime-scene-investigator/">How to Become a Crime Scene Investigator in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/cosmetologist/">How to Become a Cosmetologist in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/data-analyst/">How to Become a Data Analyst in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/fbi-agent/">How to Become a FBI Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/financial-advisor/">How to Become a Financial Advisor in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/financial-analyst/">How to Become a Financial Analyst in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/parole-officer/">How to Become a Parole Officer in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/police-officer/">How to Become a Police Officer in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-california/">How to Become a Police Officer in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-florida/">How to Become a Police Officer in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-pennsylvania/">How to Become a Police Officer in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-new-york/">How to Become a Police Officer in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-texas/">How to Become a Police Officer in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/private-investigator/">How to Become a Private Investigator in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/real-estate-agent/">How to Become a Real Estate Agent in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/realtor/">How to Become a Realtor in USA</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                    <li><a href="https://stepstobecomea.com/sheriff/">How to Become a Sheriff in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-california/">How to Become a Sheriff in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-florida/">How to Become a Sheriff in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-new-york/">How to Become a Sheriff in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-pennsylvania/">How to Become a Sheriff in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-texas/">How to Become a Sheriff in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter/">How to Become a Firefighter in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-california/">How to Become a Firefighter in California</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-florida/">How to Become a Firefighter in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-new-york/">How to Become a Firefighter in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-pennsylvania/">How to Become a Firefighter in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/firefighter-in-texas/">How to Become a Firefighter in Texas</a></li>
                                    <li><a href="https://stepstobecomea.com/cpa/">How to Become a CPA in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/forensic-scientist/">How to Become a Border Patrol in USA</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-california/">How to Become a Forensic Scientist in California</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-florida/">How to Become a Forensic Scientist in Florida</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-new-york/">How to Become a Forensic Scientist in New York</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-pennsylvania/">How to Become a Forensic Scientist in Pennsylvania</a></li>
                                    <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-texas/">How to Become a Forensic Scientist in Texas</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}
