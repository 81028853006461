import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import './States.css';
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'

export default function States() {
    const [progress, setprogress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get("https://api.schoolndegree.com/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setprogress(100)
            })
            .catch(err => {
                //console.log(err);
                setprogress(100)
            })
    }, []);
    //console.log(states);

    const d = new Date();
    let year = d.getFullYear().toString();

    return (
        <div style={{ backgroundColor: "#E6ECF0" }} className='mt-4'>
            <LoadingBar color='#07a3bc' progress={progress} height={5} />
            <Helmet>
                <title>States - Becoming a Detective - {year}</title>
                <meta name='description' content='States' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="container-fluid" style={{ backgroundColor: '#07a3bc', color: 'white' }}>
                <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                    STATES
                </div>
            </div>
            <br />
            <br />
            <div className="container">
                <div className="row">
                    {
                        states.length > 0 ?
                            states.map((item, key) => {
                                return (
                                    <div className="col-md-6" key={key}>
                                        <NavLink to={``}>
                                            <label className="sec-heading">How to Become a Detective in {item.state}</label>
                                        </NavLink>
                                    </div>
                                )
                            })
                            : ""
                    }
                </div>
            </div>
            <br />
        </div>
    )
}